#react--checkout,
#react--cart,
#react--gift-cert-checkout {
  padding: 24px;
  padding-top: 200px;
}

.cart-list {
  border-top: 1px solid #e5e5e5;
  display: table-row-group;
}

.cart-header-item {
  padding-bottom: 1em;
}
.cart-item-name {
  font-size: 16px;
}
.form-increment button {
  justify-content: center;
}

.is-srOnly {
  visibility: hidden;
  position: absolute;
}

.cart-item-info {
  float: none;
  width: 100%;

  @media screen and (min-width: 551px) {
    width: 33.3333%;
    overflow: hidden;
  }
  @media screen and (min-width: 989px) {
    width: 16.66667%;
  }
}
.cart-totals {
  list-style: none;
  margin-left: 0;
  margin-bottom: 1.5rem;
  text-align: right;
  width: 100%;
  @media (min-width: 551px) {
    float: right;
    width: 75%;
  }
  @media (min-width: 989px) {
    margin-bottom: 1rem;
    width: 58.33333%;
  }
  @media (min-width: 1261px) {
    width: 41.66667%;
  }
}

.add-media-wrapper {
  border: 1px dashed var(--primary-blue);
  color: var(--primary-blue);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px;
  display: flex;
}
.add-media-cta-wrapper {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.add-media-badge {
  background-color: var(--system--blue-100);
  border-radius: 5px;
  padding: 3px 10px;
}

.checkout-app {
  ul {
    list-style: none;
  }
  .icon svg {
    display: inline-block;
    fill: #999;
    height: 100%;
    vertical-align: top;
    width: 100%;
  }
  li.creditCardTypes-list-item {
    margin-bottom: 0;
    padding-left: 0;
    background-position: none;
  }
  :focus {
    outline-color: #4496f6;
  }
  .creditCardTypes-list {
    align-content: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: end;
    margin-left: 1.5rem;
    max-width: 17rem;
  }

  .icon--medium {
    height: 2.7692307692rem;
    width: 2.7692307692rem;
  }
  .cardIcon-icon {
    position: relative;
  }
  .payment-option-container .row {
    align-items: center;
    justify-content: space-between;
    width: 50%;
  }
  .creditCardTypes-list {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .creditCardTypes-list-item {
    display: inline-block;
    margin: 0.1875rem;
  }
  .creditCardTypes-list-item {
    opacity: 1;
    transition: all 0.6s ease-out;
    width: 3rem;
  }
  .radio-cstm {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: white;
    float: left;
    margin-right: 20px;
    border: 1px solid;
    padding: 4px 4px;
  }

  .payment-option {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .payment-option-container .radio-cstm {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: white;
    float: left;
    margin-right: 10px;
    border: 1px solid;
    padding: 2px 2px;
  }
  .optimizedCheckout-header {
    background-color: #f5f5f5;
    background-size: cover;
    border-color: #ddd;
    color: #333;
  }

  @media (min-width: 801px) {
    .optimizedCheckout-header {
      text-align: left;
    }
  }

  .optimizedCheckout-headingPrimary {
    color: #333;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  .optimizedCheckout-headingSecondary {
    color: #333;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  body {
    background-color: #fff;
  }

  .optimizedCheckout-overlay {
    background-color: #fff;
    border-color: #ddd;
    color: #333;
  }

  .optimizedCheckout-contentPrimary {
    color: #333;
    font-family: Karla, Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  .optimizedCheckout-contentSecondary {
    color: #757575;
    font-family: Karla, Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  .optimizedCheckout-buttonPrimary {
    background-color: #333;
    border-color: #ccc;
    color: #fff;
    font-family: Karla, Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  .optimizedCheckout-buttonPrimary:focus,
  .optimizedCheckout-buttonPrimary:hover {
    background-color: #666;
    border-color: rgba(0, 0, 255, 0);
    color: #fff;
  }

  .optimizedCheckout-buttonPrimary:focus {
    border-color: #4496f6;
    box-shadow: 0 0 3px #4496f6;
  }

  .optimizedCheckout-buttonPrimary:active {
    background-color: #000;
    border-color: rgba(0, 0, 255, 0);
    color: #fff;
  }

  .optimizedCheckout-buttonPrimary[disabled],
  .optimizedCheckout-buttonPrimary[disabled].active,
  .optimizedCheckout-buttonPrimary[disabled].is-active,
  .optimizedCheckout-buttonPrimary[disabled].is-loading,
  .optimizedCheckout-buttonPrimary[disabled]:focus,
  .optimizedCheckout-buttonPrimary[disabled]:hover {
    background-color: #ccc;
    border-color: rgba(0, 0, 255, 0);
    color: #fff;
  }

  .optimizedCheckout-buttonSecondary {
    background-color: #fff;
    border-color: #ccc;
    color: #333;
    font-family: Karla, Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  .optimizedCheckout-buttonSecondary:focus,
  .optimizedCheckout-buttonSecondary:hover {
    background-color: #f5f5f5;
    border-color: #999;
    color: #333;
  }

  .optimizedCheckout-buttonSecondary:focus {
    border-color: #4496f6;
    box-shadow: 0 0 3px #4496f6;
  }

  .optimizedCheckout-buttonSecondary:active {
    background-color: #e5e5e5;
    border-color: #757575;
    color: #000;
  }

  .optimizedCheckout-buttonSecondary[disabled],
  .optimizedCheckout-buttonSecondary[disabled].active,
  .optimizedCheckout-buttonSecondary[disabled].is-active,
  .optimizedCheckout-buttonSecondary[disabled].is-loading,
  .optimizedCheckout-buttonSecondary[disabled]:focus,
  .optimizedCheckout-buttonSecondary[disabled]:hover {
    background-color: #ccc;
    border-color: rgba(0, 0, 255, 0);
    color: #fff;
  }

  a {
    color: #476bef;
    font-family: Karla, Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  a:focus,
  a:hover {
    color: #002fe1;
  }

  .optimizedCheckout-orderSummary {
    background-color: #fff;
    border-color: #ddd;
    box-shadow: 0 1px 4px rgba(221, 221, 221, 0.6);
  }

  .optimizedCheckout-orderSummary-cartSection {
    border-color: #ddd;
  }

  .optimizedCheckout-checkoutStep {
    border-color: #ddd;
  }

  .optimizedCheckout-step {
    background-color: #757575;
  }

  .optimizedCheckout-step::before {
    color: #fff;
  }

  .optimizedCheckout-step svg {
    fill: white;
  }

  .optimizedCheckout-form-label {
    color: #666;
  }

  .form-field--error .form-inlineMessage,
  .form-field--error .optimizedCheckout-form-label {
    color: #d14343;
  }

  .optimizedCheckout-form-input,
  .optimizedCheckout-form-select {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: inset 0 1px 1px #e5e5e5;
    color: #333;
  }

  .optimizedCheckout-form-input:-ms-input-placeholder,
  .optimizedCheckout-form-select:-ms-input-placeholder {
    color: #999;
  }

  .optimizedCheckout-form-input::placeholder,
  .optimizedCheckout-form-select::placeholder {
    color: #999;
  }

  .form-field--error .optimizedCheckout-form-input,
  .form-field--error .optimizedCheckout-form-select {
    background-color: #fff;
    border-color: #d14343;
    box-shadow:
      0 0 3px rgba(209, 67, 67, 0.6),
      inset 0 1px 1px #e5e5e5;
  }

  .optimizedCheckout-form-input:focus,
  .optimizedCheckout-form-select:focus,
  .optimizedCheckout-form-select:hover {
    background-color: #fff;
    border-color: #4496f6;
    box-shadow:
      0 0 3px rgba(68, 150, 246, 0.6),
      inset 0 1px 1px #e5e5e5;
  }

  .optimizedCheckout-form-checkbox + .optimizedCheckout-form-label::before,
  .optimizedCheckout-form-radio + .optimizedCheckout-form-label::before {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: inset 0 1px 1px #e5e5e5;
    opacity: 1;
  }

  .optimizedCheckout-form-checkbox:checked + .optimizedCheckout-form-label::before,
  .optimizedCheckout-form-radio:checked + .optimizedCheckout-form-label::before {
    background-color: #476bef;
    border-color: #476bef;
    box-shadow: none;
  }

  .optimizedCheckout-form-checkbox:focus + .optimizedCheckout-form-label::before,
  .optimizedCheckout-form-radio:focus + .optimizedCheckout-form-label::before {
    border-color: #4496f6;
    box-shadow:
      0 0 3px rgba(68, 150, 246, 0.6),
      inset 0 1px 1px #e5e5e5;
  }

  .optimizedCheckout-form-checkbox:focus:checked + .optimizedCheckout-form-label::before,
  .optimizedCheckout-form-radio:focus:checked + .optimizedCheckout-form-label::before {
    box-shadow: 0 0 3px rgba(68, 150, 246, 0.6);
  }

  .optimizedCheckout-discountBanner {
    background-color: #e5e5e5;
    color: #333;
    font-family: Karla, Arial, Helvetica, sans-serif;
    font-weight: 400;
  }

  .optimizedCheckout-discountBanner svg {
    fill: #333333;
  }

  .optimizedCheckout-form-checklist {
    background-color: #fff;
    border-color: #ccc;
    color: #333;
  }

  .optimizedCheckout-form-checklist-item {
    background-color: #fff;
    border-color: #ccc;
  }

  .optimizedCheckout-form-checklist-item--selected,
  .optimizedCheckout-form-checklist-item:hover {
    background-color: #f5f5f5;
    border-color: #ccc;
  }

  .optimizedCheckout-form-checklist-checkbox ~ .form-label::after,
  .optimizedCheckout-form-checklist-checkbox ~ .form-label::before {
    background-color: #fff;
    border-color: #ccc;
  }

  .optimizedCheckout-form-checklist-checkbox:focus ~ .form-label {
    box-shadow: inset 0 0 0 1px #4496f6;
  }

  .optimizedCheckout-form-checklist-checkbox:checked ~ .form-label::before {
    border-color: #476bef;
  }

  .optimizedCheckout-form-checklist-checkbox:checked ~ .form-label::after {
    background: #476bef;
  }

  .optimizedCheckout-loadingToaster {
    background-color: #333;
    color: #fff;
  }

  .optimizedCheckout-header {
    background: #fff;
  }

  .optimizedCheckout-step.stepHeader-counter--complete {
    background-color: #000;
    border-color: #000;
  }

  .button--primary,
  .button--tiny,
  .optimizedCheckout-buttonSecondary,
  .optimizedCheckout-step {
    background-color: #3863ca;
    border-color: #3863ca;
    color: #fff;
  }

  .button--primary:hover,
  .button--tiny:hover,
  .optimizedCheckout-buttonSecondary:hover {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }

  .button.dropdown-button {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: inset 0 1px 1px #e5e5e5;
    color: #333;
  }

  a {
    color: #3863ca;
  }

  a:focus,
  a:hover {
    color: #3863ca;
  }

  .optimizedCheckout-buttonPrimary {
    background: #3863ca;
    border-color: #3863ca;
  }

  .optimizedCheckout-buttonPrimary:hover {
    background: #000;
    border-color: #000;
  }

  .checkoutHeader-logo {
    max-height: 100px;
  }
  .form-group,
  .form-label {
    color: black;
  }
  .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  @media (min-width: 551px) {
    .form-row {
      display: flex;
      flex-direction: row;
      margin: 0 -1.1538461538rem;
      padding: 0;
    }
  }
}
