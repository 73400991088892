.form-row--half {
  .form-field {
    width: 50%;
    display: flex;
    float: left;
  }
}

.form-field {
  padding: 0 0.75rem;
}

.location-react {
  justify-content: center;
  padding-bottom: 80px;
}

.form-row {
  // width: 100%;
}

.select-product-option {
  border-radius: 4px;
  background-position: right 0.5rem top 0.5rem;
  background-size: 1.28571rem;
  font-size: 13px;
  height: 2.28571rem;
  line-height: 1rem;
  max-width: 27rem;
  padding: 0.57143rem 0.78571rem;
  padding-right: 2rem;
  width: 100%;
  max-width: 27rem;
  margin-bottom: 12px;
}
