.tns-nav {
  text-align: center;
  margin: 10px 0;
}
.tns-nav > [aria-controls] {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
}
.tns-nav > .tns-nav-active {
  background: #999;
}
.tns-controls {
  // opacity: 0;
  // z-index: -1;
  // position: absolute;
  text-align: center;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: flex-start;
  border: 1px solid var(--primary-blue);
  border-radius: 999px;
  color: var(--primary-blue);
  display: flex;
  padding: 2px 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  button:first-child {
    border-right: 1px solid var(--primary-blue);
    padding-right: 14px;
  }
  button {
    background-color: transparent;
  }
}