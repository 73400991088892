:root {
  --neutral--200: #f7f9fc;
  --neutral--600: #6f7182;
  --neutral--800: #0b0e2c;
  --primary-red: #c2291d;
  --primary-blue: #204be2;
  --accent--primary-1: #1476ff;
  --neutral--100: white;
  --neutral--300: #e9eaf3;
  --general--shadow-01: rgba(25, 93, 194, .07);
  --general--shadow-02: rgba(11, 22, 44, .05);
  --neutral--700: #303350;
  --neutral--500: #989aad;
  --system--green-300: #05c168;
  --system--300: #ff5a65;
  --neutral--400: #cacbd7;
  --button-shadow--color-2: rgba(2, 87, 251, .2);
  --button-shadow--white-01: rgba(20, 20, 43, .04);
  --secondary--color-2: #f4f7ff;
  --system--blue-100: #eaf4ff;
  --secondary--color-11: #edfbee;
  --system--green-400: #13a570;
  --system--orange-100: #fff3e4;
  --system--orange-400: #d5691b;
  --system--red-100: #ffeff0;
  --system--red-400: #dc2b2b;
  --general--shadow-03: rgba(11, 22, 44, .1);
  --system--orange-300: #ff9e2c;
  --secondary--color-6: #ffb016;
  --secondary--color-8: #ff414c;
  --secondary--color-12: #45c74e;
  --button-shadow--white-03: rgba(20, 20, 43, .1);
  --system--green-100: #e4faed;
  --secondary--color-5: #fff7e8;
  --system--red-200: #ffbec2;
  --system--blue-400: #086cd9;
  --secondary--color-1: #1045cc;
  --secondary--color-3: #f6f1ff;
  --secondary--color-4: #9240fb;
  --system--blue-300: #1d88fe;
  --system--blue-200: #8fc3ff;
  --system--green-200: #7fdca4;
  --system--orange-200: #ffd19b;
  --general--shadow-04: rgba(20, 20, 43, .14);
  --general--shadow-05: rgba(20, 20, 43, .16);
  --general--shadow-06: rgba(20, 20, 43, .24);
  --button-shadow--color-01: rgba(74, 58, 255, .06);
  --button-shadow--white-02: rgba(20, 20, 43, .06);
  --button-shadow--color-3: rgba(74, 58, 255, .08);
  --secondary--color-7: #fff4f4;
  --secondary--color-9: #eef9ff;
  --secondary--color-10: #68caff;
}

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';
 
// webflow

// dss

@import 'danasafetysupply';

@import 'styles';
@import 'blogs';
@import 'checkout';
@import 'forms';
@import 'carousel';



