
.container.blogPost_mn {
  max-width: none;
}

.news-hero {
  color: #fff;
  background-color: #000;
  align-items: center;
  padding-top: 240px;
  padding-bottom: 100px;
  display: flex;
  position: relative;
  overflow: visible;
}

.blog-post-image img {
  max-width: 67%;
  margin: 0 auto;
}
.post-body-rich.w-richtext iframe {
  width: 540px;
  height: 440px;
}

.post-body iframe,
.post-body embed,
.post-body video {
  max-width: 90vw;
}

.news-hero-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.container.home-2-hero-flex {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 0% 0 5%;
}

.news-hero-content-wrapper {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.paragraph-20.max-width-640px {
  max-width: 640px;
}

.blog-collection-list-wrapper {
  width: 100%;
}

.f-blog-image-xl {
  height: 460px;
  border-radius: 8px;
  overflow: hidden;
}

.f-sub-heading-large {
  letter-spacing: -.02em;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.f-margin-bottom-08 {
  margin-bottom: 8px;
}

.f-image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-content-wrapper {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.f-blog-content {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.blog-read-more {
  color: #ff1f00;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
}

.f-blog-line {
  width: 100%;
  height: 1px;
  background-color: #e4e6f1;
  margin-top: 48px;
  margin-bottom: 48px;
}

.blog-collection-list {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.f-blog-thumbnail-basic {
  height: 344px;
  border-radius: 8px;
  margin-bottom: 32px;
  overflow: hidden;
}

.f-blog-badge-category-gray {
  color: #160042;
  text-align: center;
  background-color: #fbfcfe;
  border-radius: 24px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 4px 12px;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
}

.blog-card-content-wrapper {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.post-detail-icon {
  width: 25px;
  height: 25px;
}

blockquote {
  border-left: 5px solid #e2e2e2;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
}

.detail-divider {
  width: 1px;
  height: 60px;
  background-color: #dadada;
  margin-left: 30px;
  margin-right: 30px;
}

.post-detail-card {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  display: flex;
}

.post-detail-heading {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
}

.post-detail-content-card {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  align-items: center;
  display: flex;
}

.detail-divider.blog {
  margin-left: 0;
}

.post-body-rich p {
  color: #dadada;
}

.post-details-wrapper {
  display: flex;
}

.container.blogPost_mn {
  margin: 0;
  padding: 0;
}

.other-blogs-heading {
  margin-bottom: 40px;
}

@media screen and (max-width: 1024px) {

  .blog-collection-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {

  .blog-collection-list {
    grid-template-columns: 1fr;
  }
}